import React from "react"
import styles from "./question.module.css"

const Question = ({children,title}) => (
  <div className={styles.container}>
      <h2>{title}</h2>
      {children}
  </div>
)

export default Question
