// Gatsby supports TypeScript natively!
import React, { useState } from 'react';
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CEO from "../components/ceo"
import Form from "../components/form"
import Textfield from "../components/textfield"
import Head from "../components/head"
import Question from "../components/question"

const Gestionnaires = (props: PageProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [answer, setAnswer] = useState('');
  const [isSubmitting, submitting] = useState(false);
  const [isSend, send] = useState(false);

  const submitForm = async e => {

    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('answer', answer);

    e.preventDefault();
    submitting(true)
    fetch("https://api.syndic-en-un-clic.fr/answer-request", {
      method: "POST",
      body: formData
    }).then(function(response) {
      console.log(response)
      if (response.ok) {
        send(true)
      }
      submitting(false)
    })
  };

  return (
  <Layout color="primary">
    <SEO title="FAQ" />
    <Head title='Foire aux questions' color="primary">
    Vous avez une interrogation,<br/> vous trouverez sûrement la réponse ici
    </Head>
    <Question title="Pourquoi faire le choix de Syndic en un clic ?">
    Utiliser au quotidien l’application Syndic + va vous permettre d’échanger plus facilement avec votre syndic, vous pourrez également déclarer un incident technique et suivre sa résolution en temps réel de la prise en charge par le syndic jusqu’au compte rendu d’intervention réalisé par le prestataire technique. </Question>
    <Question title="En quoi Syndic en un clic va améliorer ma vie d’immeuble ?">
    Avec l’application Syndic + , vous pourrez échanger très simplement avec vos voisins sans avoir leur numéro de téléphone, par le biais d’un système de messagerie privée. Vous pourrez également poster des petites annonces pour proposer des activités communes, proposer vos services, ou prêts ou échanges d’objets, la vie d’immeuble devient ainsi plus conviviale !
    </Question>
    <Question title="Comment utiliser Syndic en un clic ?">
    La mise en place de l’application Syndic + se fait par le biais de la souscription d’un contrat de service par le syndic. Une fois le contrat de service signé, chaque résident peut s’inscrire facilement sur l’application à l’aide d’un code immeuble. Des ateliers destinés aux résidents pour bien prendre en main l’application sont proposés par visioconférence. 
    </Question>
    <Question title="Combien coûte Syndic en un clic ?">
    L’utilisation de l’application Syndic + fonctionne sur la base d’un abonnement : 1€ HT/mois et par lot d’habitation.
    </Question>
    <Head title='VOUS N’AVEZ PAS TROUVE VOTRE REPONSE ?'>
      Posez votre question Aurélie notre CEO vous répondra !
    </Head>
    <CEO/>
    <Form submit={'ENVOYER LA QUESTION'} onSubmit={submitForm} isSubmitting={isSubmitting} >
      <Textfield id="name" label="Nom & Prénom" value={name} onChange={setName}/>
      <Textfield id="email" label="Email" value={email} onChange={setEmail}/>
      <Textfield id="question" label="Question" value={answer} onChange={setAnswer}/>
    </Form>
  </Layout>
)}

export default Gestionnaires
